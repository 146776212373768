import store from '@/store'

export const splitArrayIntoGroups = (array, groupSize) => {
  const groups = []
  for (let i = 0; i < array.length; i += groupSize) {
    groups.push(array.slice(i, i + groupSize))
  }
  return groups
}

export const downloadBlob = async (blob, filename) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')

  a.href = url
  a.download = filename || 'download'

  const clickHandler = () => {
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      removeEventListener('click', clickHandler)
    }, 150)
  }

  a.addEventListener('click', clickHandler, false)
  a.click()
  return a
}

export const collectionTypes = [

  { label: 'Collection', value: 'Collection', color: '#4682B4' },
  { label: 'Extortion / Zakat', value: 'Extortion', color: '#FF6347' },
  { label: 'Maritime', value: 'Maritime', color: '#4CAF50' },
  { label: 'Kidnapping', value: 'Kidnapping', color: '#c69432' },
  { label: 'Theft', value: 'Theft', color: '#8E24AA' },
  { label: 'Police Report', value: 'PoliceReport', color: '#2E7D32' },
  { label: 'Other', value: 'Other', color: '#78909C' }
]

export const getCollectionTypes = () => {
  const theme = store.getters['theme/theme']

  if (theme && theme.collectionTypes) {
    return theme.collectionTypes
  }
  return collectionTypes
}
