<template>
  <v-card flat height="86vh" width-full>

    <div v-if="!ready" class="text-center width-full">...loading...</div>

    <v-alert v-if="ready && isDeleted" type="warning">
      {{ $t('channelDeletedWarning') }}
    </v-alert>

    <v-app-bar v-if="toolbar && ready && !isDeleted"
      dense
      elevation="0"
      color="grey lighten-2"
      style="border-bottom: 1px solid rgba(0, 0, 0, 0.12);"
    >
      <v-app-bar-title>{{ channel.name }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <member-list v-if="hasMembers" :members="channel.members" />
      <edit-channel :channel="channel" @saved="channelUpdated" @deleted="channelDeleted" />

    </v-app-bar>

    <div v-if="ready && !isDeleted" class="d-flex flex-column mb-6" style="height:80vh">
      <!-- message list -->
      <div
        style="flex: 2; overflow: scroll;"
        ref="chatMessages"
        v-scroll="checkIfScrolledToBottom"
      >
        <v-card v-if="channel" flat width="100%" class="overflow-y-auto frame">
          <v-card-text>
            <div v-if="!messages.length" class="text-center">
              {{ $t('enterFirstMessage') }}
            </div>
            <div v-if="messages.length && !noOlderMessages" class="text-center">
              <v-btn plain text @click="loadOlderMessages" class="text-caption">
                {{ $t('messagingViewOlderMessages') }}
              </v-btn>
            </div>
            <div
              v-if="messages.length && noOlderMessages"
              class="text-center text-caption"
            >
              {{ $t('messagingNoOlderMessages') }}
            </div>
            <message-card
              v-for="message in messages"
              :key="message.id"
              :message="message"
            />
          </v-card-text>
          <span ref="messagebottom"></span>
        </v-card>
      </div>

      <!-- message form-->
      <v-card flat width="100%" color="grey lighten-2" class="mb-4">
        <v-card-text>

          <v-textarea
            elevation="0"
            v-model="message"
            background-color="white"
            hide-details
            :label="$t('enterMessage')"
            rows="3"
            filled
            auto-grow
            @keydown.enter.exact.prevent="sendMessage"
            @keydown.enter.shift.exact.prevent="message += '\n'"
          ></v-textarea>
          <div v-if="files.length > 0">
            <div class="d-flex flex-row justify-start" style="max-height:300px; overflow-y: auto;">
              <!-- <pre>{{ files }}</pre> -->
              <div v-for="(media, i) in files" >
                  <v-card max-width="150px" min-width="50px" :key="i" class="ma-2">
                    <media-preview :id="media.id" :height="50"></media-preview>
                    <v-card-actions>
                      <v-btn small icon @click="removeAttachment(media.id)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <media-upload-dialog
            ref="mediaUploadDialog"
            :tenantId="tenantId"
            :entityId="channelId"
            :entityType="'channel'"
            @media-added="attachmentUploaded"
          />
          <v-btn
            color="primary"
            large
            @click="sendMessage"
            :disabled="saving || !isValid"
          >
            {{ $t('send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import profileMixin from '@/components/mixins/profile'
import { channelApiBuilder, messageApiBuilder } from '../../api'
import MessageCard from '../message/card.vue'
import MemberList from './member-list/index.vue'
import EditChannel from './edit-channel.vue'
import MediaUploadDialog from '@/components/media/media-upload-dialog.vue'
import MediaPreview from '@/components/media/media-preview2.vue'
import mediaApiBuilder from '@/services/api/media-library'

export default {
  mixins: [profileMixin],
  components: {
    EditChannel,
    MessageCard,
    MemberList,
    MediaPreview,
    MediaUploadDialog
  },
  props: {
    channelId: {
      type: String,
      required: true
    },
    toolbar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      channelApi: null,
      messageApi: null,
      channel: null,
      files: [],
      message: '',
      messages: [],
      messageOptions: {},
      noOlderMessages: false,
      isScrolledToBottom: true,
      saving: false
    }
  },
  computed: {
    ready() {
      return this.channelApi && this.channel
    },
    hasMembers() {
      return this.channel?.members?.length > 0
    },
    isDeleted() {
      return this.channel?.isDeleted
    },
    isValid() {
      return (this.message && this.message.trim().length > 0) || this.files.length > 0
    }
  },
  watch: {
    channelId(val) {
      this.fetchChannel(val)
    },
    tenantId(val) {
      if (val) {
        this.ensureApi()
      }
    },
    ready(val) {
      if (val) {
        this.scrollToBottom()
        this.markChannelAsRead()
      }
    }
  },
  async mounted() {
    this.mediaApi = mediaApiBuilder.build(this.tenantId)
    await this.fetchChannel(this.channelId)

    this.$messageHub.connection.on('MessageCreated', this.handleMessageCreated)

    this.scrollToBottom()
  },
  async beforeDestroy() {
    this.$messageHub.connection.off('MessageCreated', this.handleMessageCreated)
  },
  methods: {
    ...mapActions('messaging', ['markAsRead', 'markMessageAsRead']),
    async ensureApi() {
      if (!this.channelApi) {
        this.channelApi = channelApiBuilder.build(this.tenantId)
      }
      return this.channelApi
    },
    async handleMessageCreated(channelId, message) {
      if (channelId !== this.channelId) {
        return
      }
      this.messages.push(message)
      this.markMessageAsRead({ tenantId: this.tenantId, messageId: message.id })
      this.scrollToBottom()
    },
    async fetchChannel(val) {
      await this.ensureApi()

      if (val) {
        this.channel = await this.channelApi.fetch(val)
        this.messageApi = messageApiBuilder.build(
          this.tenantId,
          this.channel.id
        )
        const messagesResponse = await this.messageApi.get(null, {
          params: this.messageOptions
        })
        this.messages = (messagesResponse.data?.results || []).sort(
          (a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime)
        )
      }
    },
    async loadOlderMessages() {
      //todo: using pagination
      this.messageOptions.beforeDateTime = this.messages[0].createdDateTime
      const messagesResponse = await this.messageApi.get(null, {
        params: this.messageOptions
      })
      const messages = (messagesResponse.data?.results || []).sort(
        (a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime)
      )

      if (!messages.length) {
        this.noOlderMessages = true
        return
      }

      this.messages = [...messages, ...this.messages]
    },
    async markChannelAsRead() {
      const payload = { tenantId: this.tenantId, channelIds: [this.channelId] }
      this.markAsRead(payload)
    },
    async attachmentUploaded(files) {
      console.log('attachmentUploaded', files)
      this.files = [...files, ...this.files]
    },
    async removeAttachment(id) {
      const f = this.files.find(f => f.id == id)

      if(f){
        await this.mediaApi.delete(f.id)
        this.files = this.files.filter(f => f.id !== id)
      }
    },
    async sendMessage(force) {
      if(this.saving){
        return
      }

      try{
        this.saving = true;

        if (!this.message && !force) {
          return
        }

        const payload = {
          tenantId: this.tenantId,
          channelId: this.channel.id,
          message: { content: this.message }
        }

        if(this.files && this.files.length > 0){
          payload.message.attachments = this.files.map(file => {
            return {
              mediaItemId: file.id,
              title: file.fileName,
            }
          })
        }

        await this.$store.dispatch('messaging/sendMessage', payload)
        this.message = ''
        this.files = []
      }finally{
        this.saving = false
      }
    },
    checkIfScrolledToBottom() {
      const element = this.$refs.chatMessages
      if (element) {
        const atBottom =
          element.scrollTop === element.scrollHeight - element.offsetHeight
        this.isScrolledToBottom = atBottom
      }
      this.isScrolledToBottom = true
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if (!this.ready) return

        if (this.isScrolledToBottom) {
          const element = this.$refs.chatMessages
          if (element) {
            element.scrollTop = element?.scrollHeight || 0
          }
        }
      })
    },
    channelDeleted() {
      this.channel.isDeleted = true
      this.$emit('channelDeleted', this.channel)
    },
    channelUpdated(channel) {
      this.channel = channel
    }
  }
}
</script>

<style>
.frame {
  overflow-y: auto;
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
